<template>
  <div class="layout__wrapper">
    <div id="layout" class="layout__container">
      <Header />
      <div class="page__interface_wrapper" v-if="!pageLoading">
        <slot name="page" />
      </div>
      <div class="page__loader" v-if="pageLoading">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
const Header = () => import('@/components/header/Header.vue');
const Loader = () => import('@/components/loader/Loader.vue');

export default {
  name: 'PageLayout',
  data() {
    return {
      pageLoading: false,
    };
  },
  mounted() {
    this.$store.watch((state) => state.index.pageLoading, () => {
      this.pageLoading = this.$store.state.index.pageLoading;
    });
  },
  components: {
    Header,
    Loader,
  },
};
</script>
